import { useMatches } from "@remix-run/react";

import type { ValidatedUser } from "@wind/auth";
import type { AdminPermissions } from "@wind/permission";

export const useOptionalAuthUser = (): ValidatedUser<AdminPermissions> | null => {
  const matches = useMatches();

  const rootPath = matches.find((match) => match.pathname === "/");

  const data = rootPath?.data as { authUser: ValidatedUser<AdminPermissions> | null } | undefined;
  return (data?.authUser as ValidatedUser<AdminPermissions>) ?? null;
};

export const useAuthUser = () => {
  const user = useOptionalAuthUser();
  if (!user) {
    throw new Error("No auth user found");
  }

  return user;
};
